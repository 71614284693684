/*Menu*/
.ui-menu{
 z-index: 10;
}
.ui-menuitems{ 
    z-index: 10;
    position:absolute;
    white-space: nowrap;
    background-color: white;
    color:#444444;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

}
