
/*BUTTON*/
.button{
    border:none;
    outline: none;
    zzdisplay: inline-flex;
    text-transform:uppercase;
    padding:6px;
    padding-left: 8px;
    padding-right: 8px;
    margin :4px; 
    min-height: 40px;
    background-color: darkgrey;
    border-radius: 4px 4px 4px 4px;
    color:white;
    cursor:pointer; 
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-sizing:border-box;
}
.button:active{ 
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.button-label{
    padding:6px;
    box-sizing: inherit
}
.button-start-icon{
    box-sizing: inherit
}
.button-end-icon{
    box-sizing: inherit
}