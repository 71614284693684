.ui-fab{
    border:none;
    outline: none;
    display: inline-flex;
    text-transform:uppercase;
    padding:6px;
    padding-left: 8px;
    padding-right: 8px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-color: darkgrey;
    color:white;
    cursor:pointer; 
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-sizing:border-box;
}
.ui-fab:active{ 
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.ui-fab-label{
    padding:6px;
    box-sizing: inherit
}