/*DIALOG*/
.ui-dialog {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.ui-dialog-body {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 0;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 6px 6px 6px 6px;
}

.ui-dialog-body-fullscreen {
    overflow-y:scroll;
    box-sizing: border-box;
    background-color: white;
    height: 100%;
    
}

.ui-dialog-header {
    position: fixed;
    min-height: 50px; 
    text-align: left;
    overflow: auto;
    padding-left:16px;
    padding-right:16px;
    background-color: white;
    z-index: 1;
}
.ui-dialog-header-fullscreen {
    position: fixed;
    min-height: 50px; 
    text-align: left;
    padding-top:4px;
    padding-left:16px;
    padding-right:16px;
    width: 100%;
    background-color: white;
    z-index: 1;
    box-shadow: 2px 2px 4px silver !important;
}


.ui-dialog-content {
    box-sizing: border-box; 
    height: 100%;
    text-align: left;
    overflow: auto;
    padding:16px;
}
.ui-dialog-content-fullscreen {
    overflow-y:scroll;
    box-sizing: border-box;
    padding-top:50px;
    padding-bottom: 50px; 
    height: 100%;
    text-align: left;
    overflow: auto;
    padding:16px;
}

.ui-dialog-actions {
    box-sizing: border-box; 
    min-height: 50px; 
    text-align: right;
    padding-right: 8px;
    overflow: auto;
    background-color: white;
}
.ui-dialog-actions-fullscreen {
    box-sizing: border-box;
    position: fixed;
    bottom:0px;
    min-height: 50px; 
    text-align: right;
    overflow: auto;
    width: 100%;
    background-color: white;
    box-shadow: -2px -2px 4px silver !important;
}