.ui-table{
    border-collapse: collapse;
    width:100%
}
.ui-table td, .ui-table th {
   text-align: left;
    padding: 10px;
}
.ui-table td{
   cursor: pointer;
}
.ui-table tr:nth-child(even){background-color: #f9f9f9;}
.ui-table tr:hover {background-color: #ddd;}