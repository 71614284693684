.charting-title{
    font-size: 16px;
}
.charting-datalabel{
    cursor:pointer;
    text-anchor:left;
    font-size: 11px;
    color:gray;
    fill:gray;
}
.charting-datalabel:hover{
    cursor:pointer;
    text-decoration: underline;
}
.charting-datapointlabel{
    z-index:99; 
    font-size: larger;
}