.obsoleteblock-sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 200px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 0; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color:#fff; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding:5px;
    padding-top: 64px;
    box-shadow: 4px 4px 4px 4px silver;
}
.obsoleteblock-content{
    margin-right: 200px; /* Same as the width of the sidebar */
    padding: 0px 10px;
}
.block-content{
  padding-left:40px;
  padding-right:40px;
  padding-top:12px;
  padding-bottom:12px;
  font-size:large

}
.block-content ol{
  margin-left:40px;
}
.block-content ul{
  margin-left:40px;
}
.block-content li{
  padding:10px;
}
.block{
  
    padding-left:8px;
    padding-right:8px;
    margin-left:4px;
    margin-right:4px;
}
.block-container{
    margin-left:38px;
}
.block-header{
  color:black;
  cursor: pointer;
  margin-bottom: 3px;
  min-height: 50px;
  border-top:1px solid #d2d2d2;
}
.page-menu{

  padding-left:8px;
  padding-right:8px;
  padding-top:5px;
  margin-left:4px;
  margin-right:12px;
  margin-bottom: 8px;
  margin-top: 12px;
}
.block-menu{
    margin:8px;
    
    text-align: center;
    border-top: 3px solid black;
    background-color:white;
    box-shadow: 2px 2px 5px 2px silver;
}
.block-add-box{
    margin:4px;
   display: inline-block;
   vertical-align: middle;
    width:90px;
    height: 80px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    color:gray;
}
.block-add-box:hover{
  color:black;
}
.block-add-box img{
  width:40px;
  height:40px;
    
}

.block-options{
  width: 100%;
}
.block-option-left{
  border: 1px solid silver;
  border-radius: 8px 8px 8px 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-top:4px;
  background-color: white;
  float: left;
}
.block-option-right{
  border: 1px solid silver;
  border-radius: 8px 8px 8px 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-top:4px;
  background-color: white;
  float:right;
}

.block-text-on-image{
  padding-left:80px;
  padding-top:80px;
  font-size: 30px;
  color:'white'

}

.htmlbuilder-paragraph-card{
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:8px;
    width:312px;
  }
  
  .htmlbuilder-paragraph-card-image{
    margin:6px;
    width:300px;
  }

  .htmlbuilder-paragraph-card-description{
    font-style: italic;
    color:#5a5a5a;
  }
  .htmlbuilder-gallery-container{
    display:flex;
    flex-wrap: wrap;
  }
  .htmlbuilder-gallery-card{
    
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:4px;
    width:312px;
  }
  .htmlbuilder-gallery-card-image{
    margin:6px;
    width:300px;
  }
  .htmlbuilder-gallery-card-description{
    font-style: italic;
    color:#5a5a5a;
  }