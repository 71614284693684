.login-body{
    width:100%;
    text-align: center;
    padding-top:50px;

}
.login-paper{
     width:450px;
     margin:0 auto;
     overflow: hidden;
     text-align: left;
}
.login-paper-body{
    padding:20px;
}