.ui-menuitem{
    cursor: pointer;
    text-align: left;
    padding:14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ui-menuitem svg{
   padding-right:12px;
}
.ui-menuitem:hover{
    background-color: #f5f5f5;
}