.appbar{ 
    
        background-color: rgb(95, 97, 175);
        width:100%;
        min-height: 64px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        position: fixed;
        z-index: 2;
        color:white;
        display: flex;
        flex-direction: row ;
        align-items: center;
    
}