.ui-drawer{
    height: 100%;  
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: white; 
    overflow-x: hidden; 
    box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)
}
